/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-named-as-default-member */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { func, bool, any, string } from "prop-types";

import closeIcon from "../../assets/images/close-icon.svg";
import mainActions from "../../redux/actions/main";
import Button from "../common/Button";
import applicationActions from "../../redux/actions/application";

import "./index.scss";

const Popup = ({
  setPopup,
  setBlur,
  onExit,
  onResolve,
  onReject,
  question,
  onResolveTitle,
  onRejectTitle,
  withoutActions = false,
  setLocalPopup = null,
  withBtns,
  withImgs,
  withText,
  popupText,
  imgs
}) => {
  useEffect(() => {
    setBlur(true);
  }, []);

  const closePopup = () => {
    if (setLocalPopup) {
      setLocalPopup(false);
    }
    if (!setLocalPopup || setLocalPopup === null) {
      setPopup(false);
    }
    onExit();
    setBlur(false);
  };

  const handleResolve = () => {
    if (withoutActions) {
      onResolve();
      closePopup();
    } else {
      onResolve();
      closePopup();
      onExit();
    }
  };

  const handleReject = () => {
    onReject();
    closePopup();
    onExit();
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <img
          src={closeIcon}
          alt=""
          className="close-icon"
          onClick={closePopup}
        />
        {withImgs && <img src={imgs} alt="" />}
        <div className="title">
          <p className="title">{question}</p>
          {withText && <div className="popup-text">{popupText}</div>}
        </div>
        {withBtns && (
          <div className="buttons-container">
            <Button className="popup-btn-reject" handleClick={handleReject}>
              {onRejectTitle}
            </Button>
            <Button className="popup-btn-resolve" handleClick={handleResolve}>
              {onResolveTitle}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
Popup.propTypes = {
  onExit: func,
  withBtns: bool,
  withImgs: bool,
  withText: bool,
  popupText: string,
  imgs: any
};

Popup.defaultProps = {
  onExit: () => false,
  withBtns: true,
  withImgs: false,
  withText: false,
  popupText: "",
  imgs: null
};

const mapStateToProps = ({
  main: { isShowPopup, isBlur },
  application: { draftApp }
}) => ({
  isShowPopup,
  isBlur,
  draftApp
});

const mapDispatchToProps = dispatch => ({
  setBlur: isBlur => dispatch(mainActions.setBlur(isBlur)),
  setPopup: isShowPopup => dispatch(mainActions.setPopup(isShowPopup)),
  setCreateProcess: isCreatingProcess =>
    dispatch(applicationActions.setCreateProcess(isCreatingProcess)),
  setDraftEmpty: () => dispatch(applicationActions.setDraftEmpty())
});

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
