const SET_BLUR = "SET_BLUR";
const SET_QRCODE_POPUP = "SET_QRCODE_POPUP";
const SET_POPUP = "SET_POPUP";
const SET_CONTENT_INPROGRESS = "SET_CONTENT_INPROGRESS";

const setBlur = isBlur => ({
  type: SET_BLUR,
  payload: isBlur
});

const setQRCodePopup = isShowQRCodePopup => ({
  type: SET_QRCODE_POPUP,
  payload: isShowQRCodePopup
});

const setPopup = isShowProgressPopup => ({
  type: SET_POPUP,
  payload: isShowProgressPopup
});

const setContentInProgress = isContentInProgress => ({
  type: SET_CONTENT_INPROGRESS,
  payload: isContentInProgress
});

export {
  SET_BLUR,
  setBlur,
  SET_QRCODE_POPUP,
  setQRCodePopup,
  SET_POPUP,
  setPopup,
  SET_CONTENT_INPROGRESS,
  setContentInProgress
};

export default {
  SET_BLUR,
  setBlur,
  SET_QRCODE_POPUP,
  setQRCodePopup,
  SET_POPUP,
  setPopup,
  SET_CONTENT_INPROGRESS,
  setContentInProgress
};
