const SET_CONTENTS = "SET_CONTENTS";

const setContents = contents => ({
  type: SET_CONTENTS,
  payload: contents
});

export { SET_CONTENTS, setContents };

export default {
  SET_CONTENTS,
  setContents
};
