/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

import "./index.scss";

const Radiobutton = props => {
  const { name, value, onChange, checked, text } = props;

  return (
    <div className="custom-radio-button">
      <input
        type="radio"
        name={name}
        id={name + value}
        className="input-hidden"
        onChange={onChange}
        checked={checked}
        value={value}
      />
      <label htmlFor={name + value}>
        <div className="background">
          <p className="title">{text}</p>
        </div>
      </label>
    </div>
  );
};

export default Radiobutton;
