import notifications from "../utils/notifications";

const imageFields = [
  "logo",
  "splash_screen",
  "app_icon",
  "icon_like",
  "icon_unlike",
  "icon_menu",
  "icon_profile",
  "icon_comment",
  "icon_share",
  "content",
  "icon_post"
];

const filterImgUrls = async (data, appId, uploadAppImg) => {
  const filterData = { ...data };

  const imgData = {};

  Object.entries(filterData).forEach(([field, value]) => {
    if (imageFields.includes(field) && value !== null) {
      if (typeof value === "object") {
        imgData[field] = value;
      }
      delete filterData[field];
    }
  });

  if (Object.entries(imgData).length > 0) {
    try {
      await uploadAppImg(appId, imgData);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  }

  return filterData;
};

export default filterImgUrls;

export { filterImgUrls };
