import { combineReducers } from "redux";

import auth from "./auth";
import application from "./application";
import main from "./main";
import content from "./content";

const rootReducer = combineReducers({
  auth,
  application,
  main,
  content
});

export default rootReducer;
