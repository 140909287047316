/* eslint-disable import/no-named-as-default-member */
import React, { useEffect } from "react";
import { connect } from "react-redux";

import mainActions from "../../redux/actions/main";
import SuccessIcon from "../../assets/images/img-check.svg";
import FailIcon from "../../assets/images/img-close.svg";

import "./index.scss";

const ContentPopup = ({
  setIsOpenPopup,
  setBlur,
  UplodingData,
  goback,
  msg,
  successText
}) => {
  useEffect(() => {
    setBlur(true);
  });
  const handleClick = () => {
    setBlur(false);
    setIsOpenPopup(false);
    if (UplodingData.success) {
      goback();
    }
  };

  const content = {
    success: {
      text: successText,
      icon: SuccessIcon
    },
    fail: {
      text:
        "Something went wrong. Please check your internet connection, and try again!",
      icon: FailIcon
    }
  };

  return (
    <div className="content-popup-container" onClick={handleClick}>
      <div className="popup-content">
        <img
          src={UplodingData.success ? content.success.icon : content.fail.icon}
          alt=""
        />
        {msg ? (
          <p className="title">{msg}</p>
        ) : (
          <p className="title">
            {UplodingData.success ? content.success.text : content.fail.text}
          </p>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = ({ main: { isBlur } }) => ({
  isBlur
});

const mapDispatchToProps = dispatch => ({
  setBlur: isBlur => dispatch(mainActions.setBlur(isBlur))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentPopup);
