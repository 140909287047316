import React, { useRef, useState } from "react";
import classnames from "classnames";

import "./index.scss";

const Textarea = ({ styles, inputerror = "", onChange, value }) => {
  const [focused, setFocused] = useState(false);

  const textAreaRef = useRef();

  const onFocused = () => {
    setFocused(true);
  };

  const onBlured = () => {
    setFocused(false);
  };

  let withIcon = true;

  if ((textAreaRef.current && textAreaRef.current.value !== "") || focused) {
    withIcon = false;
  } else if (value && value !== "") {
    withIcon = false;
  }
  return (
    <div
      className={classnames({
        "custom-textarea": true,
        "custom-textarea error": !!inputerror,
        "with-icon": withIcon
      })}
      style={styles}
    >
      <textarea
        onChange={onChange}
        ref={textAreaRef}
        value={value}
        onFocus={onFocused}
        onBlur={onBlured}
      />
    </div>
  );
};

export default Textarea;
