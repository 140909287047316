import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import {
  getAccessTokenFromCookies,
  createAxiosResponseInterceptor
} from "./helpers/auth";

import App from "./screens/App";

import store from "./redux/store";

import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

// dev url
axios.defaults.baseURL = `https://sportslink-api.com/api/v1/`;

// prod url
// axios.defaults.baseURL = `https://sportslink-api.com/api/v1/`;

const accessToken = getAccessTokenFromCookies();

if (accessToken) {
  axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
}

createAxiosResponseInterceptor();

toast.configure();

ReactDOM.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById("root")
);
