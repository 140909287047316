const SET_START_CREATE_PROCESS = "SET_START_CREATE_PROCESS";
const UPDATE_DRAFT_APP = "UPDATE_DRAFT_APP";
const SET_APPS_LIST = "SET_APPS_LIST";
const SET_DRAFT_EMPTY = "SET_DRAFT_EMPTY";
const UPDATE_SERVER_DRAFT_APP = "UPDATE_SERVER_DRAFT_APP";
const SET_IS_DISABLED_NEXT = "SET_IS_DISABLED_NEXT";
const UPDATE_ANDROID_PUBLISH_INFO = "UPDATE_ANDROID_PUBLISH_INFO";
const UPDATE_IOS_PUBLISH_INFO = "UPDATE_IOS_PUBLISH_INFO";
const SET_EMPTY_PUBLISH_INFO = "SET_EMPTY_PUBLISH_INFO";
const UPDATE_QR_DATA = "UPDATE_QR_DATA";

const setCreateProcess = isCreatingProcess => ({
  type: SET_START_CREATE_PROCESS,
  payload: isCreatingProcess
});

const setServerDraftApp = serverDraftApp => ({
  type: UPDATE_SERVER_DRAFT_APP,
  payload: serverDraftApp
});

const updateDraftApp = draftApp => ({
  type: UPDATE_DRAFT_APP,
  payload: draftApp
});
const setAppsList = appsList => ({
  type: SET_APPS_LIST,
  payload: appsList
});

const setDraftEmpty = () => ({
  type: SET_DRAFT_EMPTY
});

const setIsDisabledNext = isDisabled => ({
  type: SET_IS_DISABLED_NEXT,
  payload: isDisabled
});

const updateAndroidPublishInfo = data => ({
  type: UPDATE_ANDROID_PUBLISH_INFO,
  payload: data
});

const updateIosPublishInfo = data => ({
  type: UPDATE_IOS_PUBLISH_INFO,
  payload: data
});

const setEmptyPublishInfo = () => ({
  type: SET_EMPTY_PUBLISH_INFO
});

const updateQrData = data => ({
  type: UPDATE_QR_DATA,
  payload: data
});

export {
  SET_APPS_LIST,
  SET_START_CREATE_PROCESS,
  UPDATE_DRAFT_APP,
  SET_DRAFT_EMPTY,
  UPDATE_SERVER_DRAFT_APP,
  SET_IS_DISABLED_NEXT,
  UPDATE_ANDROID_PUBLISH_INFO,
  UPDATE_IOS_PUBLISH_INFO,
  SET_EMPTY_PUBLISH_INFO,
  UPDATE_QR_DATA,
  setAppsList,
  setCreateProcess,
  setServerDraftApp,
  updateDraftApp,
  setDraftEmpty,
  setIsDisabledNext,
  updateAndroidPublishInfo,
  updateIosPublishInfo,
  setEmptyPublishInfo,
  updateQrData
};

export default {
  SET_APPS_LIST,
  SET_START_CREATE_PROCESS,
  UPDATE_SERVER_DRAFT_APP,
  UPDATE_DRAFT_APP,
  SET_DRAFT_EMPTY,
  SET_IS_DISABLED_NEXT,
  UPDATE_ANDROID_PUBLISH_INFO,
  UPDATE_IOS_PUBLISH_INFO,
  SET_EMPTY_PUBLISH_INFO,
  UPDATE_QR_DATA,
  setAppsList,
  setCreateProcess,
  setServerDraftApp,
  updateDraftApp,
  setDraftEmpty,
  setIsDisabledNext,
  updateAndroidPublishInfo,
  updateIosPublishInfo,
  setEmptyPublishInfo,
  updateQrData
};
