/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
import axios from "axios";
import { objectToFormData } from "object-to-formdata";

import convertToFormData from "../helpers/publishToFormData";
import { filterImgUrls } from "../helpers/images";

const uploadAppImg = async (id, data) => {
  const formData = objectToFormData(data);

  const res = await axios({
    method: "patch",
    url: `/applications/${id}/`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" }
  });

  return res.data;
};

const createApp = async data => {
  const formData = objectToFormData(data);

  const res = await axios({
    method: "post",
    url: "/applications/",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" }
  });

  return res.data;
};

const getApplications = async queries => {
  if (queries.nextUrl && queries.nextUrl !== "") {
    const { data } = await axios({
      baseURL: queries.nextUrl,
      method: "get"
    });
    return data;
  }
  const defaultQueries = {
    is_published: "true",
    limit: 10
  };

  let queriesString = "?";

  for (let [key, query] of Object.entries(defaultQueries)) {
    if (queries.hasOwnProperty(key)) {
      query = queries[key];
    }
    if (query !== "") {
      queriesString += `${key}=${query}&`;
    }
  }

  queriesString = queriesString.slice(0, -1);
  const { data } = await axios.get(`/applications/${queriesString}`);

  return data;
};

const updateApplication = async data => {
  const filteredData = await filterImgUrls(data, data.id, uploadAppImg);

  const res = await axios.put(`/applications/${data.id}/`, filteredData);

  return res.data;
};

const rePublishApp = async id => {
  const res = await axios.post(`/applications/${id}/re-publish/`);

  return res.data;
};

const getApplication = async id => {
  const { data } = await axios.get(`/applications/${id}/`);

  return data;
};

const getPublishInfo = async (id, platform) => {
  const { data } = await axios.get(
    `/application-${platform}-publish-info/${id}`
  );

  return data;
};

const createPublishInfo = async (data, platform) => {
  const fd = convertToFormData(data);

  const res = await axios({
    method: "post",
    url: `/application-${platform}-publish-info/`,
    data: fd,
    headers: { "Content-Type": "multipart/form-data" }
  });

  return res.data;
};

const updatePublishInfo = async (id, data, platform) => {
  const fd = convertToFormData(data);

  const res = await axios({
    method: "patch",
    url: `application-${platform}-publish-info/${id}/`,
    data: fd,
    headers: { "Content-Type": "multipart/form-data" }
  });

  return res.data;
};

const setPublishIcons = async (id, data, platform) => {
  const formData = objectToFormData(data);

  const res = await axios({
    method: "post",
    url: `/application-${platform}-publish-info/${id}/images/`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" }
  });

  return res.data;
};

const deletePublishIcon = async (id, imageId, platform) => {
  const res = await axios.delete(
    `/application-${platform}-publish-info/${id}/images/${imageId}/`
  );

  return res.data;
};

const getAnalytics = async ({ fromDate, toDate, id }) => {
  const { data } = await axios.get(
    `/analytics/?from_date=${fromDate}&to_date=${toDate}&app=${id}`
  );

  return data;
};

const getUsersCsv = async id => {
  const { data } = await axios.get(`/applications/${id}/users-in-file/`);

  return data;
};

export {
  createApp,
  getApplications,
  updateApplication,
  rePublishApp,
  getPublishInfo,
  createPublishInfo,
  updatePublishInfo,
  deletePublishIcon,
  setPublishIcons,
  getAnalytics,
  getUsersCsv
};

export default {
  createApp,
  getApplications,
  updateApplication,
  rePublishApp,
  getApplication,
  getPublishInfo,
  createPublishInfo,
  updatePublishInfo,
  deletePublishIcon,
  setPublishIcons,
  getAnalytics,
  getUsersCsv
};
