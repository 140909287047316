import axios from "axios";

const adminLogIn = async data => {
  const res = await axios.post("/auth/admin/login/", {
    ...data
  });

  return res.data;
};

const exchangeToken = async token => {
  const { data } = await axios.post("/auth/token/refresh/", { token });

  return data;
};
const getCurrentUser = async () => {
  const { data } = await axios.get("/user-profile/");

  return data;
};

const updateToken = async token => {
  const { data } = await axios.post("/auth/token/refresh/", { refresh: token });

  return data;
};

const getApplicationUser = async queries => {
  if (queries.nextUrl && queries.nextUrl !== "") {
    const { data } = await axios({
      baseURL: queries.nextUrl,
      method: "get"
    });
    return data;
  }
  const defaultQueries = {
    app: queries.app_id,
    role: queries.role
  };

  let queriesString = "?";

  for (let [key, query] of Object.entries(defaultQueries)) {
    if (queries.hasOwnProperty(key)) {
      query = queries[key];
    }
    if (query !== "") {
      queriesString += `${key}=${query}&`;
    }
  }

  queriesString = queriesString.slice(0, -1);
  const { data } = await axios.get(`/users/${queriesString}`);

  return data;
};

export {
  getApplicationUser,
  adminLogIn,
  exchangeToken,
  updateToken,
  getCurrentUser
};

export default {
  adminLogIn,
  exchangeToken,
  updateToken,
  getCurrentUser,
  getApplicationUser
};
