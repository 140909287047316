/* eslint-disable no-fallthrough */
/* eslint-disable prefer-const */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-debugger */

const convertToFormData = data => {
  const fd = new FormData();

  const filteredData = { ...data };

  const isObject = value => value === Object(value);

  const isArray = value => Array.isArray(value);

  const isBlob = value =>
    value &&
    typeof value.size === "number" &&
    typeof value.type === "string" &&
    typeof value.slice === "function";

  const isFile = value =>
    isBlob(value) &&
    typeof value.name === "string" &&
    (typeof value.lastModifiedDate === "object" ||
      typeof value.lastModified === "number");

  for (const [filteredDataKey, filteredDataValue] of Object.entries(
    filteredData
  )) {
    if (isArray(filteredDataValue)) {
      if (filteredDataValue.length) {
        filteredDataValue.forEach((element, index) => {
          if (element.image) {
            if (typeof element.image === "string") {
              if (element.image.substring(0, 4) !== "http") {
                fd.append(`${filteredDataKey}[${index}]image`, element.image);
              }
            } else {
              fd.append(`${filteredDataKey}[${index}]image`, element.image);
            }
          }
        });
      }
    } else if (
      isObject(filteredDataValue) &&
      !isFile(filteredDataValue) &&
      !isBlob(filteredDataValue)
    ) {
      for (const [objKey, objValue] of Object.entries(filteredDataValue)) {
        fd.append(`${filteredDataKey}.${objKey}`, objValue);
      }
    } else {
      const isString = typeof filteredDataValue === "string";

      if (isString) {
        if (filteredDataValue.substring(0, 4) !== "http") {
          fd.append(filteredDataKey, filteredDataValue);
        }
      } else {
        fd.append(filteredDataKey, filteredDataValue);
      }
    }
  }
  return fd;
};

export default convertToFormData;
