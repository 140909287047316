import React, { useRef, useState } from "react";
import classnames from "classnames";

import "./index.scss";

const Input = props => {
  const { inputerror, type = "text", value, onBlur, withIcon = true } = props;

  const [focused, setFocused] = useState(false);

  const inputRef = useRef();

  const onFocused = () => {
    setFocused(true);
  };

  const onBlured = () => {
    if (onBlur) {
      onBlur();
    }
    setFocused(false);
  };
  let showIcon = true;

  if ((inputRef.current && inputRef.current.value !== "") || focused) {
    showIcon = false;
  } else if (value && value !== "") {
    showIcon = false;
  }

  return (
    <div
      className={classnames({
        "custom-input": true,
        "custom-input error": !!inputerror,
        "with-icon": withIcon && showIcon
      })}
      onClick={() => inputRef.current.focus()}
    >
      <input
        {...props}
        type={type}
        ref={inputRef}
        onFocus={onFocused}
        onBlur={onBlured}
      />
    </div>
  );
};

export default Input;
