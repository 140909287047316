import React from "react";
import { connect } from "react-redux";

import { Route, withRouter } from "react-router-dom";

import authActions from "../../redux/actions/auth";

import Loader from "../Loader";

const PrivateRoute = ({
  currentUser,
  setCurrentUser,
  component,
  history,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const signOutAndRedirectToLogin = () => {
        history.push("/login");
      };

      if (!currentUser) {
        signOutAndRedirectToLogin();
        return <Loader />;
      }

      return React.createElement(component, props);
    }}
  />
);

const mapStateToProps = ({ auth: { currentUser } }) => ({ currentUser });

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(authActions.setCurrentUser(user))
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
);
