import { connect } from "react-redux";
import React, { useState, useEffect } from "react";

import applicationActions from "../../../redux/actions/application";
import notifications from "../../../utils/notifications";

import "./index.scss";

const InputImg = ({
  name,
  placeholder,
  onChange,
  withoutName,
  value,
  styles = {},
  setIsDisabledNext,
  withRequirement
}) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const fileChangedHandler = event => {
    const file = event.target.files[0];

    if (!file) {
      notifications.error({
        msg: "The file is not supported format"
      });
    } else {
      const reader = new FileReader();

      reader.onloadend = async () => {
        setImagePreviewUrl(reader.result);
        onChange(file, reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const deleteImage = async () => {
    onChange(null);
    setImagePreviewUrl(null);
    setIsDisabledNext(false);
  };

  useEffect(() => {
    if (typeof value === "string") {
      setImagePreviewUrl(value);
    }
  }, [value]);

  return (
    <div className="wrapper" style={styles}>
      {imagePreviewUrl && <div className="hover" onClick={deleteImage} />}
      <label htmlFor={name}>
        <div
          className={
            imagePreviewUrl ? "custom-input-img selected" : "custom-input-img"
          }
        >
          <input
            type="file"
            className="input-img"
            name={name}
            id={name}
            onChange={fileChangedHandler}
            value=""
          />
          {imagePreviewUrl && (
            <div className="image-container">
              <img className="img-preview" src={imagePreviewUrl} alt="icon" />
            </div>
          )}
        </div>
      </label>
      {!withoutName && <p className="title">{placeholder}</p>}
      {withRequirement && (
        <p className="img-requirement">
          Requirement for image: the longer dimension of the image should be at
          least 250px
        </p>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  setIsDisabledNext: isDisabledNext =>
    dispatch(applicationActions.setIsDisabledNext(isDisabledNext))
});

export default connect(null, mapDispatchToProps)(InputImg);
