/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { SlideDown } from "react-slidedown";

import applicationActions from "../../../redux/actions/application";
import InputImg from "../../common/InputImg";
import Input from "../../common/Input";
import Textarea from "../../common/Textarea";
import Switcher from "../../Switcher";
import arrow from "../../../assets/images/arrow-down.svg";
import questionImg from "../../../assets/images/questionmark.svg";

import Popup from "../../Popup";

import "react-slidedown/lib/slidedown.css";
import "./index.scss";

const AccordionItem = ({
  title,
  hint,
  configurations,
  content,
  defaultOpen = false,
  onChange,
  onChangeBlur,
  onChangeNotification,
  checked,
  isContent = false,
  updateDraftApp,
  draftApp,
  qrData,
  updateQrData,
  isNotificationsOn,
  onEmptyPremiumBlur
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [isShowInfoPopup, setShowInfoPopup] = useState(false);

  return (
    <div className={isContent ? "accordion-item content" : "accordion-item"}>
      {isShowInfoPopup && (
        <Popup
          withBtns={false}
          withText
          popupText="This delays the premium blur with the amount of seconds you enter, no longer than 60 seconds. The content remains premium and blocks actions such as commenting or clicking, but is visible for Х seconds"
          setLocalPopup={setShowInfoPopup}
          question="Premium Blur"
        />
      )}
      <div className="accordion-item-header" onClick={() => setIsOpen(!isOpen)}>
        <p className="title">{title}</p>
        <img
          className={classnames({ "arrow-up": isOpen })}
          src={arrow}
          alt="arrow"
        />
      </div>
      <SlideDown closed={!isOpen}>
        <div className="accordion-item-content">
          <p className="accordion-item-hint">{hint}</p>
          {title === "post" && (
            <div className="post-extra-info">
              <InputImg
                placeholder="Post icon"
                name="postImg"
                onChange={result => {
                  updateDraftApp({ ...draftApp, icon_post: result });
                }}
                value={draftApp.icon_post}
              />
              <div className="chatterbox-input">
                <p> CHATTERBOX TITLE</p>
                <Input
                  onChange={event => {
                    updateDraftApp({
                      ...draftApp,
                      content_module: {
                        ...draftApp.content_module,
                        post: {
                          ...draftApp.content_module.post,
                          chatterbox_title: event.target.value
                        }
                      }
                    });
                    updateQrData({
                      ...qrData,
                      content_module: {
                        ...qrData.content_module,
                        post: {
                          ...qrData.content_module.post,
                          chatterbox_title: event.target.value
                        }
                      }
                    });
                  }}
                  value={
                    draftApp.content_module.post.chatterbox_title
                      ? draftApp.content_module.post.chatterbox_title
                      : ""
                  }
                />
              </div>
            </div>
          )}
          <div className="accordion-item-configurations">
            {configurations.map(item =>
              item.is_input ? (
                <div key={item.value} className="configuration">
                  <div className="title-container">
                    <span>{item.title}</span>
                    <img
                      className="hint-icon"
                      src={questionImg}
                      onClick={() => setShowInfoPopup(true)}
                      alt=""
                    />
                  </div>
                  <Input
                    readOnly={!content.is_premium}
                    withIcon={false}
                    value={content[item.value]}
                    onBlur={onEmptyPremiumBlur}
                    onChange={event =>
                      onChangeBlur({
                        event,
                        title: item.title,
                        value: item.value
                      })
                    }
                    type="number"
                    className="configuration-input"
                  />
                </div>
              ) : (
                <div key={item.value} className="configuration">
                  <span>{item.title}</span>
                  <Switcher
                    onChange={event => {
                      onChange({
                        event,
                        title: item.title,
                        value: item.value
                      });
                    }}
                    checked={checked(item)}
                  />
                </div>
              )
            )}
            {isNotificationsOn && (
              <div className="notification-container">
                <div className="notification-title">
                  <p className="notification-paragraph">Notification title</p>
                  <Textarea
                    onChange={event => {
                      onChangeNotification({
                        event,
                        value: "notification_title"
                      });
                    }}
                    value={
                      content.notification_title !== null
                        ? content.notification_title
                        : ""
                    }
                  />
                </div>
                <div className="notification-text">
                  <p className="notification-paragraph">Notification text</p>
                  <Textarea
                    onChange={event => {
                      onChangeNotification({
                        event,
                        value: "notification_body"
                      });
                    }}
                    value={
                      content.notification_body !== null
                        ? content.notification_body
                        : ""
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </SlideDown>
    </div>
  );
};

const mapStateToProps = ({ application: { draftApp, qrData } }) => ({
  draftApp,
  qrData
});

const mapDispatchToProps = dispatch => ({
  updateDraftApp: res => dispatch(applicationActions.updateDraftApp(res)),
  updateQrData: data => dispatch(applicationActions.updateQrData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccordionItem);
