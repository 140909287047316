import React from "react";
import { func } from "prop-types";
import classnames from "classnames";

import "./index.scss";

const Button = ({ children, className, handleClick, disabled }) => {
  return (
    <button
      type="button"
      className={classnames({
        button: true,
        [className]: true
      })}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  handleClick: func
};

Button.defaultProps = {
  handleClick: null
};

export default Button;
