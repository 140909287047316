import React from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

import contentActions from "../../../redux/actions/content";
import ContenstMenu from "./ContentsMenu";
import CreateEditContent from "./CreateEditContent";

import "./index.scss";

const Contents = () => {
  return (
    <div className="content-wrapper">
      <Switch>
        <Route exact path="/dashboard/:id/contents" component={ContenstMenu} />
        <Route
          path="/dashboard/:id/contents/create-content"
          component={CreateEditContent}
        />
        <Route
          path="/dashboard/:id/contents/edit-content/:contentid"
          component={CreateEditContent}
        />
      </Switch>
    </div>
  );
};

const mapStateToProps = ({ content: { contents } }) => ({ contents });

const mapDispatchToProps = dispatch => ({
  setContens: contents => dispatch(contentActions.setContents(contents))
});

export default connect(mapStateToProps, mapDispatchToProps)(Contents);
