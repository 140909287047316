import React, { useState } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import moment from "moment";
import { Link } from "react-router-dom";

import arrowLeftImg from "../../../assets/images/arrow_left.svg";
import arrowRightImg from "../../../assets/images/arrow_right.svg";
import likeIcon from "../../../assets/images/ic-like.svg";
import messageIcon from "../../../assets/images/ic-mess.svg";
import shareIcon from "../../../assets/images/share.svg";
import deleteIcon from "../../../assets/images/delete.svg";
import editIcon from "../../../assets/images/settings-1365.svg";
import picturePlaceholder from "../../../assets/images/img-placeholder.jpg";
import postPlaceholder from "../../../assets/images/chat_thumbnail.png";

import Popup from "../../Popup";

import "./index.scss";

const ContentsListItem = ({
  content,
  removeContent,
  handleDelete,
  isOpenPopup,
  setIsOpenPopup,
  draftApp
}) => {
  const [isOpen, setIsOpen] = useState(false);

  let imgSrc = picturePlaceholder;

  if (
    (content.type === "photo" ||
      content.type === "music" ||
      content.type === "event" ||
      content.type === "merchandise" ||
      content.type === "article" ||
      content.type === "news") &&
    content.link
  ) {
    imgSrc = content.link;
  } else if (content.type === "video" && content.preview_link) {
    imgSrc = content.preview_link;
  } else if (content.type === "post") {
    if (draftApp.icon_post) {
      imgSrc = draftApp.icon_post;
    } else {
      imgSrc = postPlaceholder;
    }
  }

  let description = "";
  if (content.button_text) {
    description =
      content.button_text.length > 102
        ? `${content.button_text.slice(0, 100)}...`
        : content.button_text;
  } else if (content.content_description) {
    description =
      content.content_description.length > 102
        ? `${content.content_description.slice(0, 100)}...`
        : content.content_description;
  }

  const handleCancel = () => {
    setIsOpenPopup(false);
  };

  return (
    <div className="content-container">
      {isOpenPopup && (
        <Popup
          onExit={handleCancel}
          onResolve={removeContent}
          question="Are you sure you want to delete this content ?"
          onReject={handleCancel}
          onResolveTitle="DELETE"
          onRejectTitle="CANCEL"
        />
      )}
      <div className="content">
        <div className="image">
          <img src={imgSrc} alt="content-img" />
        </div>

        <div className="content-info">
          <div className="content-description">
            <span>{description}</span>
          </div>
          <div
            className={classnames({ "content-created": true, hided: isOpen })}
          >
            <span className="created-date">
              {moment
                .utc(content.created_at)
                .local()
                .format("DD MMMM, YYYY")}
            </span>
            <span className="created-time">
              {moment
                .utc(content.created_at)
                .local()
                .format("kk:mm")}
            </span>
            <img
              src={arrowLeftImg}
              alt="arrow-img"
              className="open-extra-btn"
              onClick={() => setIsOpen(true)}
            />
          </div>
        </div>

        <div
          className={classnames({
            "extra-info-container": true,
            opened: isOpen
          })}
        >
          <div className="extra-info">
            <img
              onClick={() => setIsOpen(false)}
              src={arrowRightImg}
              className="close-extra-btn"
              alt="close-img"
            />
            <span className="content-creator">
              {content.type} by <span>{content.username}</span>
            </span>
            <div className="count">
              <img src={likeIcon} alt="like" />
              <span>{content.likes_count}</span>
            </div>

            <div className="count">
              <img src={messageIcon} alt="comments" />
              <span>{content.comments_count}</span>
            </div>

            <div className="count">
              <img src={shareIcon} alt="share" />
              <span>{content.shares_count}</span>
            </div>

            <img
              className="delete-icon"
              onClick={() => handleDelete(content.id)}
              src={deleteIcon}
              alt="delete"
            />
            {content.type !== "archived" && (
              <Link
                to={`/dashboard/${content.app}/contents/edit-content/${content.id}`}
              >
                <img
                  className="edit-icon"
                  src={editIcon}
                  alt="edit"
                  onClick={() => handleDelete(content.id)}
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ application: { draftApp } }) => ({ draftApp });

export default connect(mapStateToProps)(ContentsListItem);
