/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import classnames from "classnames";

import arrow from "../../assets/images/ic-drop.svg";
import CalendarIcon from "../../assets/images/calendar.svg";

import "./index.scss";

const Filters = ({ onSelectItem, items, isStatistic, onSelectCalendar }) => {
  const [selectedItem, setSelectedItem] = useState(items[0]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [selectedItem]);

  const selectItem = async item => {
    setSelectedItem(item);
    await onSelectItem(item);
  };
  return (
    <div className="filters-container">
      <div className="filters-header">
        <span className="filters-title">
          {selectedItem === "All" ? "All content" : selectedItem}
        </span>
      </div>
      {isOpen && (
        <div className="filters">
          {items.map(content => (
            <div className="filter-item" key={content}>
              <input
                type="radio"
                id={content}
                name="content"
                checked={selectedItem === content}
                onChange={() => selectItem(content)}
              />
              <label htmlFor={content}>{content}</label>
            </div>
          ))}
          {isStatistic && (
            <div
              className="custom-range-item"
              onClick={() => {
                onSelectCalendar(true);
                selectItem("Custom range");
                setIsOpen(false);
              }}
            >
              <img src={CalendarIcon} alt="" />
              <label htmlFor="calendar">Set custom range</label>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Filters;
