/* eslint-disable import/no-named-as-default-member */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Input from "../../components/common/Input";
import accountService from "../../services/account";
import notifications from "../../utils/notifications";
import authActions from "../../redux/actions/auth";
import Button from "../../components/common/Button";

import {
  setAccessTokenToCookies,
  setAccessTokenToAxios,
  setRefreshTokenToCookies,
  setUserRoleToCookies
} from "../../helpers/auth";

import "./index.scss";

const Login = ({ history, setCurrentUser, currentUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (currentUser !== null) history.push("/");
  }, [currentUser]);

  const login = async () => {
    try {
      const { access, refresh } = await accountService.adminLogIn({
        email,
        password
      });

      setAccessTokenToCookies(access);
      setRefreshTokenToCookies(refresh);

      setAccessTokenToAxios(access);

      const user = await accountService.getCurrentUser();

      setUserRoleToCookies(user.role);

      setCurrentUser({ ...user });

      history.push("/");
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const onKeyPressed = e => {
    if (e.key === "Enter") {
      login();
    }
  };

  return (
    <div className="login-container">
      <div className="login-content" onKeyDown={onKeyPressed} tabIndex="0">
        <h4 className="login-title">LET’S GET STARTED!</h4>
        <h6 className="login-placeholder">
          You can log in with your email adress
        </h6>
        <div className="login-email">
          <Input
            placeholder="Email"
            type="email"
            onChange={event => setEmail(event.target.value)}
          />
        </div>
        <div className="login-password">
          <Input
            placeholder="Password"
            type="password"
            onChange={event => setPassword(event.target.value)}
          />
        </div>
        <Button className="login-btn" handleClick={login}>
          Log in
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth: { currentUser } }) => ({
  currentUser
});
const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(authActions.setCurrentUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
