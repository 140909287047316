/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-return-assign */
import Cookies from "js-cookie";
import axios from "axios";

import history from "../utils/history";

import accountService from "../services/account";

const MONTH = 30;
const DAY = 1;
const HOUR = DAY / 24;
const MINUTE = HOUR / 60;

const getAccessTokenFromCookies = () => Cookies.get("accessToken");

const setAccessTokenToCookies = accessToken =>
  Cookies.set("accessToken", accessToken, { expires: 240 * MINUTE });

const removeAccessTokenFromCookies = () => Cookies.remove("accessToken");

const getRefreshTokenFromCookies = () => Cookies.get("refreshToken");

const setRefreshTokenToCookies = refreshToken =>
  Cookies.set("refreshToken", refreshToken, { expires: 3 * MONTH });

const removeRefreshTokenFromCookies = () => Cookies.remove("refreshToken");

const setAccessTokenToAxios = accessToken =>
  (axios.defaults.headers.Authorization = `Bearer ${accessToken}`);

const removeAccessTokenFromAxios = () =>
  (axios.defaults.headers.Authorization = "");

const getUserRoleFromCookies = () => Cookies.get("userRole");

const setUserRoleToCookies = userRole => Cookies.set("userRole", userRole);

const removeUserRoleFromCookies = () => Cookies.remove("userRole");

const removeAllTokens = () => {
  removeAccessTokenFromAxios();
  removeAccessTokenFromCookies();
  removeRefreshTokenFromCookies();
  removeUserRoleFromCookies();
};

const createAxiosResponseInterceptor = () => {
  const interceptor = axios.interceptors.response.use(
    response => response, // on success
    error => {
      const refreshToken = getRefreshTokenFromCookies();

      const logInLink = `/login`;

      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      if (!refreshToken) {
        removeAllTokens();
        history.push(logInLink);
        return Promise.reject(error);
      }

      axios.interceptors.response.eject(interceptor);

      return accountService
        .updateToken(refreshToken)
        .then(({ access: newAccessToken }) => {
          setAccessTokenToCookies(newAccessToken);
          error.response.config.headers.Authorization = `Bearer ${newAccessToken}`;
          window.location.reload();
          return axios(error.response.config);
        })
        .catch(err => {
          if (!getAccessTokenFromCookies()) {
            removeAllTokens();
            history.push(logInLink);
          }

          return Promise.reject(err);
        })
        .finally(createAxiosResponseInterceptor);
    }
  );
};

const getCurrentUser = async ({
  history,
  setCurrentUser,
  role,
  onSuccess,
  onFail
}) => {
  const logInLink = `/login`;

  const userRole = getUserRoleFromCookies();

  if (role !== undefined) {
    if (userRole !== role) return history.push(logInLink);
  }

  const accessToken = getAccessTokenFromCookies();
  const refreshToken = getRefreshTokenFromCookies();

  if (!accessToken) {
    if (refreshToken) {
      const { access: newAccessToken } = await accountService.updateToken(
        refreshToken
      );

      setAccessTokenToCookies(newAccessToken);
      setAccessTokenToAxios(newAccessToken);
    } else {
      removeAllTokens();

      onFail();

      return history.push(logInLink);
    }
  }

  try {
    const user = await accountService.getCurrentUser();
    setCurrentUser({ ...user });

    onSuccess();
  } catch (err) {
    removeAllTokens();

    history.push(logInLink);

    onFail();
  }
};

export {
  getAccessTokenFromCookies,
  setAccessTokenToCookies,
  removeAccessTokenFromCookies,
  getRefreshTokenFromCookies,
  setRefreshTokenToCookies,
  removeRefreshTokenFromCookies,
  setAccessTokenToAxios,
  removeAccessTokenFromAxios,
  removeUserRoleFromCookies,
  getUserRoleFromCookies,
  setUserRoleToCookies,
  removeAllTokens,
  getCurrentUser,
  createAxiosResponseInterceptor
};

export default {
  getAccessTokenFromCookies,
  setAccessTokenToCookies,
  removeAccessTokenFromCookies,
  getRefreshTokenFromCookies,
  setRefreshTokenToCookies,
  removeRefreshTokenFromCookies,
  setAccessTokenToAxios,
  removeAccessTokenFromAxios,
  removeUserRoleFromCookies,
  getUserRoleFromCookies,
  setUserRoleToCookies,
  removeAllTokens,
  getCurrentUser,
  createAxiosResponseInterceptor
};
