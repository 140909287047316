import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const Switcher = withStyles(theme => ({
  root: {
    width: 51,
    height: 31,
    padding: 1,
    "box-shadow": "inset -1px 1px 3px 0 rgba(0, 0, 0, 0.3)",
    "border-radius": 15
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + $track": {
        backgroundColor: "#f1d19e",
        opacity: 1,
        border: "none"
      }
    },
    "&$focusVisible $thumb": {
      color: "#ffffff",
      "box-shadow": "0 2px 4px 0 rgba(0, 0, 0, 0.3)"
    }
  },
  thumb: {
    width: 27,
    height: 27
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: "#6b6163",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

export default Switcher;
