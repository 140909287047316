import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import Filters from "../../../../components/Filters";
import ContentsList from "../../../../components/ContentsList";

import notifications from "../../../../utils/notifications";
import contentService from "../../../../services/content";
import contentActions from "../../../../redux/actions/content";
import Loader from "../../../../components/Loader";
import plusImg from "../../../../assets/images/img-plus-biger.svg";

import "./index.scss";

const filterItems = ["Article"];

const ContentsMenu = ({ setContens, contents }) => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [nextContentsUrl, setNextContentsUrl] = useState("");
  const userId = useSelector(state => state.auth.currentUser.id);
  const userRole = useSelector(state => state.auth.currentUser.role);

  const fetchContents = async item => {
    let type = "article";

    if (item !== undefined) {
      if (["Photos", "Events", "Posts", "Videos"].includes(item)) {
        type = item.substring(0, item.length - 1).toLowerCase();
      } else {
        type = item !== "Archive" ? item.toLowerCase() : "archived";
      }
    }

    try {
      const queries = {
        app: id,
        type
      };

      const res = await contentService.getApplicationContents(queries);
      setNextContentsUrl(res.next);
      setContens(res.results);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
    setLoading(false);
  };

  const loadMoreContents = async () => {
    try {
      const resContents = await contentService.getApplicationContents({
        nextUrl: nextContentsUrl
      });
      await setContens([...contents, ...resContents.results]);
      setNextContentsUrl(resContents.next);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  useEffect(() => {
    fetchContents();
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="contents-container">
      <div className="contents-header">
        <Filters
          items={filterItems}
          onSelectItem={item => fetchContents(item)}
        />
        <Link to={`/dashboard/${id}/contents/create-content`}>
          <img src={plusImg} alt="plus-icon" className="add-new-content-btn" />
        </Link>
      </div>
      <div className="contents">
        <InfiniteScroll
          dataLength={contents.length}
          next={loadMoreContents}
          height="80vh"
          hasMore={nextContentsUrl !== null}
        >
          <ContentsList items={contents} fetchContents={fetchContents} />
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = ({ content: { contents } }) => ({ contents });

const mapDispatchToProps = dispatch => ({
  setContens: contents => dispatch(contentActions.setContents(contents))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentsMenu);
