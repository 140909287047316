/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import _ from "lodash";
import { useParams, withRouter } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import notifications from "../../../../utils/notifications";
import contentService from "../../../../services/content";
import accountService from "../../../../services/account";
import ArrowDownIcon from "../../../../assets/images/arrow-down.svg";
import ArticleInputs from "../../../../components/ContentsInput/ArticleInputs";
import ContentPopup from "../../../../components/ContentPopup";
import mainActions from "../../../../redux/actions/main";
import "./index.scss";

const CreateEditContent = ({ history, setContentInProgress }) => {
  const [currentContentType, setContentType] = useState("article");
  const [creators, setCreators] = useState([]);
  const [UplodingData, setUploadingData] = useState({
    success: false,
    inprogress: false
  });
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isDisabledShare, setDisabledShare] = useState(false);

  const { id } = useParams();
  const { contentid } = useParams();

  const username = useSelector(state => state.auth.currentUser.username);
  const draftApp = useSelector(state => state.application.draftApp);
  const { pages, content_module, tags } = draftApp;
  const [NextCreatorUrl, setNextCreatorUrl] = useState("");

  const getCreators = async () => {
    try {
      const { results, next } = await accountService.getApplicationUser({
        nextUrl: NextCreatorUrl,
        app_id: draftApp.id,
        role: "creator1,creator2,creator3"
      });
      await setCreators([...creators, ...results]);
      setNextCreatorUrl(next);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };

  const updateContentKeys = appContentModule => {
    let updateContentType = {};

    if (appContentModule) {
      updateContentType = {
        likes_enabled: appContentModule.likes,
        is_premium: appContentModule.premium,
        comments_enabled: appContentModule.comment,
        shares_enabled: appContentModule.share,
        send_notification: appContentModule.notification,
        main_feed: true
      };
    }
    return updateContentType;
  };
  const [content, setContent] = useState({
    ...updateContentKeys(content_module.article),
    app: id,
    type: "article",
    username
  });
  const [contentShadow, setContentShadow] = useState({
    ...updateContentKeys(content_module.article),
    app: id,
    type: "article",
    username
  });

  const userId = useSelector(state => state.auth.currentUser.id);
  const currentUserRole = useSelector(state => state.auth.currentUser.role);

  const contentsType = ["article"];

  const checkResponse = res => {
    if (res && res.status && (res.status === 200 || res.status === 201)) {
      setUploadingData({ success: true, inprogress: false });
      setIsOpenPopup(true);
      setContentInProgress(false);
    } else {
      setUploadingData({ success: false, inprogress: false });
      setIsOpenPopup(true);
    }
  };

  useEffect(() => {
    if (!content.main_feed && !content.page) {
      setDisabledShare(true);
    } else {
      setDisabledShare(false);
    }
  }, [content.main_feed, content.page]);

  /* Check if all required inputs are filled */
  /* NOTE: this is only valid for articles */
  const checkInputs = () => {
    if (content.type !== "article") {
      return;
    }
    const res = {};
    res.is_okay = false;
    // eslint-disable-next-line no-prototype-builtins
    switch (true) {
      case !content.hasOwnProperty("button_text") || !content.button_text:
        res.input_label = "Title";
        break;
      case !content.hasOwnProperty("merchandise_caption") ||
        !content.merchandise_caption: // Short Description
        res.input_label = "Short Description";
        break;
      case !content.hasOwnProperty("price") || !content.price: // Link
        res.input_label = "Link";
        break;
      case !content.hasOwnProperty("song_title") || !content.song_title: // Button Caption
        res.input_label = "Button Caption";
        break;
      case !content.hasOwnProperty("language") || !content.language: // Language
        res.input_label = "Language";
        break;
      case !content.hasOwnProperty("content_description") ||
        !content.content_description: // Content of article
        res.input_label = "Content of article";
        break;
      case !content.hasOwnProperty("link") || !content.link: // Image
        res.input_label = "Image";
        break;
      default:
        res.is_okay = true; // if all fields are okay, return true
    }
    // eslint-disable-next-line consistent-return
    return res;
  };

  const showValidationError = message => {
    notifications.error({ msg: message });
  };

  const createContent = async () => {
    // if user is not admin, if is creator1/2/3 add user_id automatically to request regardingwhatever he selected
    if (currentUserRole !== "admin") {
      content.creator_id = userId;
    }

    /* Validate input for articles */
    if (content.type === "article") {
      const res = checkInputs();
      if (res.is_okay === false) {
        showValidationError(`${res.input_label} is required.`);
        return;
      }
    }

    setUploadingData({ ...UplodingData, inprogress: true });
    content.user_id = userId;
    const res = await contentService.createContent(content);
    checkResponse(res);
  };

  const updateContent = async () => {
    // if user is not admin, if is creator1/2/3 add user_id automatically to request regardingwhatever he selected
    if (currentUserRole !== "admin") {
      content.creator_id = userId;
    }

    /* Validate input for articles */
    if (content.type === "article") {
      const res = checkInputs();
      if (res.is_okay === false) {
        showValidationError(`${res.input_label} is required.`);
        return;
      }
    }

    setUploadingData({ ...UplodingData, inprogress: true });
    content.user_id = userId;
    const res = await contentService.updateContent(content);
    checkResponse(res);
  };
  const nlTagSelectOptions = [];
  const enTagSelectOptions = [];
  tags.forEach(tag => {
    nlTagSelectOptions.push({
      value: tag.tag,
      label: tag.tag
    });
    enTagSelectOptions.push({
      value: tag.tag_alt,
      label: tag.tag_alt
    });
  });
  if (content.tags) {
    content.tags.forEach(tag => {
      if (!nlTagSelectOptions.find(tagOption => tagOption.value === tag))
        nlTagSelectOptions.push({
          value: tag,
          label: tag
        });
      if (!enTagSelectOptions.find(tagOption => tagOption.value === tag))
        enTagSelectOptions.push({
          value: tag,
          label: tag
        });
    });
  }
  const creatorsSelectOptions = creators.map(creator => ({
    value: creator.id,
    label: creator.username
  }));
  const languageSelectOptions = [
    {
      value: "nl",
      label: "Dutch"
    },
    {
      value: "en",
      label: "English"
    }
  ];
  const tagSelectOptions = {
    nl: nlTagSelectOptions,
    en: enTagSelectOptions,
    general: nlTagSelectOptions
  };

  const pageEnabled = draftApp.content_page_enabled;
  const notificationEnabled = draftApp.content_notification_enabled;

  const getContentType = contentType => {
    switch (contentType) {
      case "article":
        return (
          <ArticleInputs
            currentUserRole={currentUserRole}
            setContent={setContent}
            content={content}
            pages={pages}
            pageEnabled={pageEnabled}
            notificationEnabled={notificationEnabled}
            tags={tagSelectOptions}
            creators={creatorsSelectOptions}
            languages={languageSelectOptions}
          />
        );
      default:
        return "Uknown media type";
    }
  };
  const getContent = async () => {
    try {
      const res = await contentService.getContent(contentid);
      setContentShadow(res);
      setContent(res);
      setContentType(res.type);
    } catch (err) {
      notifications.error({
        msg:
          err.response && err.response.data
            ? Object.values(err.response.data).join(", ")
            : err.message
      });
    }
  };
  useEffect(() => {
    if (contentid) {
      getContent();
    }
  }, []);

  useEffect(() => {
    getCreators();
  }, []);

  useEffect(() => {
    const { body } = document;
    if (isOpenPopup) {
      body.style.overflowY = "hidden";
    } else {
      body.style.overflowY = "unset";
    }
  }, [isOpenPopup]);

  useEffect(() => {
    if (_.isEqual(content, contentShadow)) {
      setContentInProgress(false);
    } else {
      setContentInProgress(true);
    }
  }, [content]);

  return (
    <div
      className={`create-content-container ${
        UplodingData.inprogress ? "off-clicking" : ""
      } `}
    >
      {isOpenPopup && (
        <ContentPopup
          onExit={() => setIsOpenPopup(false)}
          setIsOpenPopup={setIsOpenPopup}
          UplodingData={UplodingData}
          goback={() => history.goBack()}
          successText={
            currentContentType === "video"
              ? "Content is being processed and will be published soon!"
              : "Congratulation! Your content is published."
          }
        />
      )}
      <div className="create-content-header">
        <div
          className="return-button"
          onClick={() => {
            setContentType("article");
            history.goBack();
            setContentInProgress(false);
          }}
        >
          <img src={ArrowDownIcon} alt="" />
          <p className="title">
            {contentid ? "Edit Article" : "Create new Article"}
          </p>
        </div>
        {/* {!contentid && ( */}
        {/*  <div> */}
        {/*    <p className="title">CONTENT CATEGORY</p> */}
        {/*    <div className="content-category-container"> */}
        {/*      {contentsType.map(contentType => ( */}
        {/*        <div */}
        {/*          key={contentType} */}
        {/*          onClick={() => { */}
        {/*            setContentType(contentType); */}
        {/*            setContent({ */}
        {/*              ...updateContentKeys(content_module[contentType]), */}
        {/*              app: id, */}
        {/*              type: contentType, */}
        {/*              username */}
        {/*            }); */}
        {/*          }} */}
        {/*          className={classnames({ */}
        {/*            "content-category-item": true, */}
        {/*            selected: currentContentType === contentType */}
        {/*          })} */}
        {/*        > */}
        {/*          <span className="item-title">{contentType}</span> */}
        {/*        </div> */}
        {/*      ))} */}
        {/*      <div className="placeholder" /> */}
        {/*      <div className="placeholder" /> */}
        {/*      <div className="placeholder" /> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* )} */}
      </div>
      <div className="content-input-container">
        {getContentType(currentContentType)}
      </div>
      <div className="create-content-footer">
        <div
          className={classnames({
            "share-btn": true,
            disabled: isDisabledShare
          })}
          onClick={contentid ? updateContent : createContent}
        >
          <p>{contentid ? "SAVE CHANGES" : "SHARE"}</p>
          {UplodingData.inprogress && (
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  setContentInProgress: isContentInProgress =>
    dispatch(mainActions.setContentInProgress(isContentInProgress))
});

export default withRouter(connect(null, mapDispatchToProps)(CreateEditContent));
