const SET_CURRENT_USER = "SET_CURRENT_USER";

const setCurrentUser = currentUser => ({
  type: SET_CURRENT_USER,
  payload: currentUser
});

export { SET_CURRENT_USER, setCurrentUser };

export default {
  SET_CURRENT_USER,
  setCurrentUser
};
