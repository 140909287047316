import React from "react";
import Creatable from "react-select/creatable";
import ReactSelect from "react-select";

import "./index.scss";

const Select = ({
  className,
  error,
  isSearchable = false,
  isCreatable = true,
  ...otherProps
}) => {
  return isCreatable ? (
    <div className={`select-container ${className}`}>
      <Creatable
        isSearchable={isSearchable}
        classNamePrefix="Select"
        {...otherProps}
      />
      {error && <small className="error-message">{error}</small>}
    </div>
  ) : (
    <div className={`select-container ${className}`}>
      <ReactSelect
        isSearchable={isSearchable}
        classNamePrefix="Select"
        {...otherProps}
      />
      {error && <small className="error-message">{error}</small>}
    </div>
  );
};

export default Select;
