import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";

import PrivateRoute from "../components/PrivateRoute";
import Header from "../components/Header";
import Loader from "../components/Loader";
//important - leave this to keep design on content create
import Dashboard from "./Dashboard";
import Login from "./Login";

import authHelper from "../helpers/auth";
import authActions from "../redux/actions/auth";

import "./App.scss";
import "./CreateApp/index.scss";

const App = ({ isBlurred, history, setCurrentUser }) => {
  const [isLoading, setLoadingStatus] = useState(true);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    authHelper.getCurrentUser({
      history,
      setCurrentUser,
      onSuccess: () => setLoadingStatus(false),
      onFail: () => setLoadingStatus(false)
    });
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className={classnames({ "app-container": true, blurred: isBlurred })}>
      {isBlurred && <div className="background-blur" />}

      <Header />

      <div className="main-container">
        <div className="main-content">
          <Switch>
            <PrivateRoute path="/dashboard/:id" component={Dashboard} />
            <Route exact path="/login" component={Login} />

            <Redirect to="/dashboard/3d45d46b-8964-43d6-a5e1-5baba3ef5884/contents/" />
          </Switch>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ main: { isBlurred } }) => ({
  isBlurred
});

const mapDispatchToProps = dispatch => ({
  setCurrentUser: (...props) => dispatch(authActions.setCurrentUser(...props)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
