import {
  SET_BLUR,
  SET_QRCODE_POPUP,
  SET_POPUP,
  SET_CONTENT_INPROGRESS
} from "../actions/main";

const initialState = {
  isBlurred: false,
  isShowQRCodePopup: false,
  isShowPopup: false,
  isContentInProgress: false
};

const mainReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BLUR:
      return { ...state, isBlurred: payload };
    case SET_QRCODE_POPUP:
      return { ...state, isShowQRCodePopup: payload };
    case SET_POPUP:
      return { ...state, isShowPopup: payload };
    case SET_CONTENT_INPROGRESS:
      return { ...state, isContentInProgress: payload };
    default:
      return state;
  }
};

export default mainReducer;
