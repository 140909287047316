import { SET_CONTENTS } from "../actions/content";

const initialState = {
  contents: []
};

const contentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CONTENTS:
      return {
        ...state,
        contents: payload
      };
    default:
      return state;
  }
};

export default contentsReducer;
