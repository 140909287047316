import {
  SET_START_CREATE_PROCESS,
  UPDATE_DRAFT_APP,
  SET_APPS_LIST,
  SET_DRAFT_EMPTY,
  UPDATE_SERVER_DRAFT_APP,
  SET_IS_DISABLED_NEXT,
  UPDATE_ANDROID_PUBLISH_INFO,
  UPDATE_IOS_PUBLISH_INFO,
  SET_EMPTY_PUBLISH_INFO,
  UPDATE_QR_DATA
} from "../actions/application";

const androidPublishDraft = {
  screenshots: [{ id: 1 }],
  high_res_app_icon: {},
  features: [{ id: 1 }],
  promo_graphics: [{ id: 1 }],
  product_detail: {
    title: "",
    short_description: "",
    full_description: "",
    store_link: ""
  },
  categorization: {
    app_type: "",
    category: "",
    content_rating: ""
  },
  contact: {
    privacy_policy_url: "",
    youtube_promo_video_url: "",
    email_address: "",
    website: ""
  }
};

const iosPublishDraft = {
  app_store_icon: {},
  screenshots: [{ id: 1 }],
  app_information: {
    name: "",
    subtitle: "",
    privacy_policy_url: "",
    promotional_text: "",
    description: "",
    keywords: "",
    support_url: "",
    marketing_url: "",
    store_link: ""
  },
  general_information: {
    bundle_id: "",
    sku: "",
    apple_id: "",
    age_rating: "",
    primary_language: "",
    category: "",
    secondary_category: "",
    copyright: ""
  }
};

const initialState = {
  isCreatingProcess: false,
  draftApp: {
    name: "",
    url_identifier: "",
    logo: null,
    app_icon: null,
    splash_screen: null,
    icon_like: null,
    icon_unlike: null,
    icon_post: null,
    app_module: {},
    content_module: {},
    template: 2,
    colors: {},
    fonts: {
      primary: "Roboto",
      secondary: "Roboto"
    },
    pages: []
  },
  qrData: {},
  androidPublishInfo: androidPublishDraft,
  iosPublishInfo: iosPublishDraft,
  serverDraftApp: null,
  appsList: [],
  isDisabledNext: false
};

const applicationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_START_CREATE_PROCESS:
      return {
        ...state,
        isCreatingProcess: payload
      };
    case UPDATE_DRAFT_APP:
      return {
        ...state,
        draftApp: payload
      };
    case UPDATE_SERVER_DRAFT_APP:
      return {
        ...state,
        serverDraftApp: payload
      };
    case SET_APPS_LIST:
      return {
        ...state,
        appsList: payload
      };
    case SET_DRAFT_EMPTY:
      return {
        ...state,
        draftApp: {}
      };
    case SET_IS_DISABLED_NEXT:
      return {
        ...state,
        isDisabledNext: payload
      };
    case UPDATE_ANDROID_PUBLISH_INFO:
      return {
        ...state,
        androidPublishInfo: payload
      };
    case UPDATE_IOS_PUBLISH_INFO:
      return {
        ...state,
        iosPublishInfo: payload
      };
    case SET_EMPTY_PUBLISH_INFO:
      return {
        ...state,
        androidPublishInfo: androidPublishDraft,
        iosPublishInfo: iosPublishDraft
      };
    case UPDATE_QR_DATA:
      return {
        ...state,
        qrData: payload
      };
    default:
      return state;
  }
};

export default applicationReducer;
